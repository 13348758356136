import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import units from "../../utils/units";
import { FaRegEdit, FaPlus, FaList } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";

const AddRecipeModal = ({ saveClick, onClick }) => {
  const [hasError, handleHttpRequest] = useHttp();

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const addIngredient = async (values) => {
    // const response = await handleHttpRequest("recipe/add", "POST", values);
    const response = await handleHttpRequest({
      page: "recipe/add",
      method: "POST",
      data: values,
    });
    if (!hasError) {
      saveClick(response);
    } else {
      closeModal();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      yeild_qty: "",
      yeild_measure: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(" Recipe Name is required.")
        .max(100, "Recipe name is too long"),
      yeild_qty: Yup.number()
        .typeError("Yeild quantity must be a number")
        .required("Enter the yeild quantity"),
      yeild_measure: Yup.string().required(
        "Select a Measurement for the Recipe."
      ),
    }),
    onSubmit: (values) => {
      addIngredient(values);
      formik.resetForm();
    },
  });

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  NEW RECIPE
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  <form className="" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="name"
                          >
                            Recipe Name*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <fieldset>
                          <label
                            htmlFor="ingred-dfltunitmeas"
                            className="lblrequired lbltheme"
                          >
                            Yield Measurement*
                          </label>
                          <select
                            className="form-select"
                            name="yeild_measure"
                            id="yeild_measure"
                            value={formik.values.yeild_measure}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option selected>Select Measurement</option>
                            {units.map((unit) => (
                              <option key={unit.id} value={unit.unit}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>
                      <div className="col-sm-3">
                        <fieldset>
                          <label
                            htmlFor="yeild_qty"
                            class="lblrequired lbltheme"
                          >
                            Yield Quantity
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="yeild_qty"
                            name="yeild_qty"
                            value={formik.values.yeild_qty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col d-flex align-self-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-link btn-just-icon"
                          id="SaveIngred"
                          name="SaveIngred"
                          title="Save"
                        >
                          <IoIosSave className="save-icon" />
                        </button>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div id="qadd-ingred-errmsg">
                        {formik.errors.name
                          ? formik.errors.name
                          : formik.errors.yeild_measure
                          ? formik.errors.yeild_measure
                          : formik.errors.yeild_qty
                          ? formik.errors.yeild_qty
                          : null}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const AddRecipe = (props) => {
  return ReactDOM.createPortal(
    <AddRecipeModal saveClick={props.saveClick} onClick={props.onClick} />,
    document.getElementById("modal")
  );
};

export default AddRecipe;
