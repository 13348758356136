import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import style from "../assets/style/table.module.css";
import GlobalFilter from "./GlobalFilter";

const Table = (props) => {
  const [currentPage, setCurrentPage] = useState(3);
  //
  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };
  const renderPaginationButton = (
    pagesToShow,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage
  ) => {
    const pageButtons = [];
    const startPage = Math.max(0, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(pageCount - 1, startPage + pagesToShow - 1);

    pageButtons.push(
      <button
        type="button"
        className="btn btn-outline-dark"
        key={0}
        onClick={() => {
          handlePageChange(3);
        }}
        disabled={!canPreviousPage}
      >
        1
      </button>
    );

    if (currentPage > 4) {
      pageButtons.push(<span key="elipse">...</span>);
    }

    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      // console.log(i);
      if (i == pageCount) {
        break;
      } else {
        pageButtons.push(
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={() => {
              handlePageChange(i);
              gotoPage(i);
            }}
            key={i}
          >
            {i}
          </button>
        );
      }
    }

    if (currentPage + 2 < pageCount) {
      pageButtons.push(<span key="elipse">...</span>);
    }

    //Last button
    pageButtons.push(
      <button
        type="button"
        className="btn btn-outline-dark"
        key={pageCount}
        onClick={() => {
          handlePageChange(pageCount - 2);
        }}
        disabled={!canPreviousPage}
      >
        {pageCount}
      </button>
    );

    return pageButtons;
  };
  //
  const columns = useMemo(() => props.columns, []);
  const tableInstance = useTable(
    {
      columns: columns,
      data: props.data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    setGlobalFilter,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize, globalFilter } = state;

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="">
          <span> Show </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setCurrentPage(3);
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>
      <table
        id={style["custom-table"]}
        {...getTableProps()}
        className="table display compact"
        width="100%"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className={style["table-sorting"]}>
                    {column.canSort &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <>
                            <span
                              className={`${style["table-sorting-up"]} ${style["active"]}`}
                            >
                              ▲
                            </span>
                            <span className={style["table-sorting-down"]}>
                              ▼
                            </span>
                          </>
                        ) : (
                          <>
                            <span className={style["table-sorting-up"]}>▲</span>
                            <span
                              className={`${style["table-sorting-down"]} ${style["active"]}`}
                            >
                              ▼
                            </span>
                          </>
                        )
                      ) : (
                        <>
                          <span className={style["table-sorting-up"]}>▲</span>
                          <span className={style["table-sorting-down"]}>▼</span>
                        </>
                      ))}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody id={style["custom-table-body"]} {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          {page.length === 0 && (
            <tr>
              <p>No data found!</p>
            </tr>
          )}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <span>
          {/* Showing
          {pageIndex === 0 ? 1 : pageSize * pageIndex} to{" "}
          {page.length > pageSize * (pageIndex + 1)
            ? pageSize * (pageIndex + 1)
            : page.length}{" "}
          of {page.length} entries */}
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <div>
          <button
            type="button"
            className="btn btn-outline-dark mx-2"
            key={0}
            onClick={() => {
              previousPage();
              // handlePageChange(3);
            }}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-outline-dark"
            key={0}
            onClick={() => {
              nextPage();
              // handlePageChange(3);
            }}
            disabled={!canNextPage}
          >
            Next
          </button>
          {/* {page.length > pageSize  && renderPaginationButton(pageSize,pageOptions.length,canNextPage,canPreviousPage,gotoPage)} */}
        </div>
      </div>
    </div>
  );
};

export default Table;
