import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import AddSupplier from "./AddSupplier";
import EditSupplierModalW from "./EditSupplier";
import DeleteSupplier from "./DeleteSupplier";
import SetSupplierStatus from "./SetSupplierStatus";
import Notification from "../../components/Notification";
import useHttp from "../../hooks/useHttp";
import { FaRegEdit, FaPlus, FaFileImport } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { Toast } from "bootstrap";
import { Link } from "react-router-dom";
import SupplierButtonGroup from "../../components/SupplierButtonGroup";
import ImportSuppliers from "../imports/ImportSuppliers";
import { ToastContainer, toast } from "react-toastify";

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [beUpdated, setBEUpdated] = useState(false);
  // const [toastUpdated, setToastUpdated] = useState(false);

  const editIdIs = useRef(0);
  const deleteIdIs = useRef(0);
  const supplierIdIs = useRef(0);
  const supplierStatus = useRef();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };

  const saveSupplierHandler = (datas, status) => {
    setIsAddModalOpen(false);
    if (status === 200) {
      toast.info(datas);
    } else {
      console.log("Errors: ", datas);
      datas?.map((data) => {
        toast.error(data);
      });
    }
    // status === 200 ? toast.info(data) : toast.error(data);
    setBEUpdated((state) => !state);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const openEditModal = (id) => {
    editIdIs.current = id;
    setIsEditModalOpen((state) => !state);
  };
  const editSupplierHandler = (datas, status) => {
    setIsEditModalOpen(false);
    if (status === 200) {
      toast.info(datas);
    } else {
      datas?.map((data) => {
        toast.error(data);
      });
    }
    // status === 200 ? toast.info(data) : toast.error(data);
    setBEUpdated((state) => !state);
  };

  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteSupplierHandler = (data) => {
    setIsDeleteModalOpen(false);
    console.log("Delete supplier", data);
    data[1] === 200 ? toast.info(data[0]) : toast.error(data[0]);
    setBEUpdated((state) => !state);
  };
  //issetStatusModalOpen
  const [issetStatusModalOpen, setIssetStatusModalOpen] = useState(false);
  const openSetStatusModal = (id, status) => {
    supplierIdIs.current = id;
    supplierStatus.current = status;
    setIssetStatusModalOpen((state) => !state);
  };
  const setStatusHandler = (data, status) => {
    setIssetStatusModalOpen(false);
    status === 200 ? toast.info(data) : toast.error(data);
    setBEUpdated((state) => !state);
  };

  const formatPhoneNumber = (phone) => {
    const match = phone.match(/^(\d{3})(\d{3})(\d{4})/);
    if (match) {
      return "(" + match[1] + ")" + " " + match[2] + " " + match[3];
    }
  };

  const COLUMNS = [
    {
      Header: "Supplier Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "suppliercontacts[0].email",
    },
    {
      Header: "Phone",
      accessor: "suppliercontacts[0].phone",
      Cell: ({ value }) => (value ? formatPhoneNumber(value) : ""),
    },
    {
      Header: "Status",
      accessor: "is_active",
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`badge rounded-pill ${
            cell.value ? "text-bg-primary" : "text-bg-danger"
          }`}
          style={{ color: "#000" }}
        >
          {cell.value == 1 ? "Active" : "In-active"}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <SupplierButtonGroup
            cell={cell.value}
            openEditModal={openEditModal}
            deletable={cell.row.original.deletable}
            openDeleteModal={openDeleteModal}
            isActive={cell.row.original.is_active}
            openSetStatusModal={openSetStatusModal}
          />
        </div>
      ),
    },
  ];

  const [hasError, handleHttpRequest] = useHttp();
  const getSuppliers = async () => {
    const response = await handleHttpRequest({
      page: "suppliers",
      method: "GET",
    });

    if (!hasError) {
      setSuppliers(response.message);
    }
  };

  useEffect(() => {
    getSuppliers();
  }, [beUpdated, setBEUpdated]);

  const [isSupplierImportOpen, setIsSupplierImportOpen] = useState(false);
  const toggleSupplierImport = (type) => {
    setIsSupplierImportOpen((state) => !state);
  };
  const handleSupplierImportResponse = (response) => {
    setIsSupplierImportOpen(false);
    // toast.info(response);
    // setIsSalesUpdated((state) => !state);
  };
  const handleSupplierErrorResponse = (message) => {
    setIsSupplierImportOpen(false);
    // toast.error(message);
  };

  return (
    <MainPanel>
      {isSupplierImportOpen && (
        <ImportSuppliers
          onClose={toggleSupplierImport}
          onImport={handleSupplierImportResponse}
          onError={handleSupplierErrorResponse}
        />
      )}
      {isEditModalOpen && (
        <EditSupplierModalW
          editId={editIdIs.current}
          onClick={openEditModal}
          updateClick={editSupplierHandler}
        />
      )}
      {isdeleteModalOpen && (
        <DeleteSupplier
          deleteId={deleteIdIs.current}
          onClick={openDeleteModal}
          onDelete={deleteSupplierHandler}
        />
      )}
      {issetStatusModalOpen && (
        <SetSupplierStatus
          supplierId={supplierIdIs.current}
          currentStatus={supplierStatus.current}
          onClick={openSetStatusModal}
          onToggle={setStatusHandler}
        />
      )}
      {isAddModalOpen && (
        <AddSupplier onClick={openAddModal} saveClick={saveSupplierHandler} />
      )}
      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">
              SUPPLIERS
              <button
                type="button"
                className="btn btn-outline-light ms-3"
                name="AddSupplier"
                title="New Supplier"
                style={{ padding: "4px 8px 7px" }}
                onClick={openAddModal}
              >
                <FaPlus />
              </button>
              {/* <button
                type="button"
                className="btn btn-outline-light ms-3"
                name="importsuppliers"
                title="Import Suppliers"
                style={{ padding: "4px 8px 7px" }}
                onClick={toggleSupplierImport}
              >
                <FaFileImport />
              </button> */}
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="toolbar"></div>
          <Table data={suppliers} columns={COLUMNS} />
        </div>
      </div>
      {/* <Notification
        toastRef={toastRef}
        toast={toast}
        setToast={setToast}
        notificationStatus={notificationStatus.current}
      >
        {notificationMessage.current}
      </Notification> */}
      <ToastContainer position="bottom-right" hideProgressBar />
    </MainPanel>
  );
};

export default Suppliers;
