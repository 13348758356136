import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import { ToastContainer, toast } from "react-toastify";
import ReceiveOrder from "./ReceiveOrder";
import ReceiveOrderButtonGroup from "../../components/groupButtons/ReceiveOrderButtonGroup";

const EditPurchaseOrder = () => {
  const [_, handleHttpRequest] = useHttp();
  const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const { id: order_id } = useParams();
  const [openReceiveOrder, setOpenReceiveOrder] = useState();
  const lineItem = useRef();
  const [purchaseOrderStateUpdate, setPurchaseOrderStateUpdate] =
    useState(false);

  const getPurchaseOrders = async () => {
    const response = await handleHttpRequest({
      page: "purchaseorderitem-order/" + order_id,
      method: "GET",
    });
    if (!response.status) {
      setPurchaseOrderItems(response.purchaseOrderItems);
      setPurchaseOrder(response.purchaseOrder);
    } else {
      //
    }
  };

  const sendItemsToCart = async (purchaseOrderItemId) => {
    const response = await handleHttpRequest({
      page: "purchaseorderitem-sendtocart/" + purchaseOrderItemId,
      method: "GET",
    });
    if (!response.status) {
      toast.info(response);
      setPurchaseOrderStateUpdate((state) => !state);
    } else {
      toast.error(response.data[0]);
    }
  };

  const voidRemaining = async (purchaseOrderItemId) => {
    await new Promise((resolve) => {
      alert("Void remaining unreceived items?");
      resolve();
    });

    const response = await handleHttpRequest({
      page: "receiveorder-void/" + purchaseOrderItemId,
      method: "PUT",
    });
    if (!response.status) {
      toast.info(response);
      setPurchaseOrderStateUpdate((state) => !state);
    } else {
      toast.error(response.data[0]);
    }
  };

  useEffect(() => {
    getPurchaseOrders();
  }, [purchaseOrderStateUpdate]);

  const calcExtendedPrice = (cell) => {
    const extendedPrice =
      cell.row.original?.ordered_qty *
      cell.row.original?.supplieringredient.price_per_sold_by;
    return "$" + extendedPrice;
  };

  const COLUMNS = [
    {
      Header: "Item #",
      accessor: "supplieringredient.supplier_item_number",
    },
    {
      Header: "Item Desc",
      accessor: "supplieringredient.supplier_item_desc",
    },
    {
      Header: "Supplier UOM",
      accessor: "supplieringredient.sold_by",
    },
    {
      Header: "Ordered Qty",
      accessor: "ordered_qty",
    },
    {
      Header: "Unit Price",
      accessor: "supplieringredient.price_per_sold_by",
      Cell: (cell) => (cell.value ? "$" + cell.value : ""),
    },
    {
      Header: "Extended Qty",
      accessor: "recievd_qty",
      Cell: (cell) => calcExtendedPrice(cell),
    },
    {
      Header: "Received Qty",
      accessor: "received_qty",
    },
    {
      Header: "Remaining Qty",
      accessor: "remaining_qty",
    },
    {
      Header: "Voided",
      accessor: "voided_qty",
    },
    {
      Header: "Status",
      accessor: "order_status",
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`badge rounded-pill ${
            (cell.value == "Recvd" && "text-bg-success") ||
            (cell.value == "Voided" && "text-bg-danger") ||
            (cell.value == "Part recvd" && "text-bg-warning") ||
            (cell.value == "Not recvd" && "text-bg-primary")
          }`}
          style={{ color: "#000" }}
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <ReceiveOrderButtonGroup
            cell={cell}
            editItem={toggleOpenReceiveOrder}
            sendToCart={sendItemsToCart}
            voidRemaining={voidRemaining}
          />
        </div>
      ),
    },
  ];

  //ReceiveOrder
  const toggleOpenReceiveOrder = (id = null) => {
    setOpenReceiveOrder((state) => !state);
    lineItem.current = id;
  };

  const handlePurchaseOrderUpdate = () => {
    setPurchaseOrderStateUpdate((state) => !state);
  };

  return (
    <>
      {openReceiveOrder && (
        <ReceiveOrder
          id={lineItem.current}
          updatePurchaseOrder={handlePurchaseOrderUpdate}
          onClick={toggleOpenReceiveOrder}
        />
      )}
      <MainPanel>
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">{purchaseOrder?.supplier?.name}</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <div className="row mt-4">
              <div className="col-md-3">
                <div className="row">
                  <strong>Purchase Order Number:</strong>
                </div>
                <div className="row">
                  <label className="form-label fw-bold texttheme">
                    {purchaseOrder?.purchase_order_number}
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <strong>Purchase Order Date:</strong>
                </div>
                <div className="row">
                  <label className="form-label fw-bold texttheme">
                    {purchaseOrder?.purchase_order_date}
                  </label>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="row">
                  <strong>Order Total</strong>
                </div>
                <div className="row">
                  <label className="form-label fw-bold texttheme">
                    {purchaseOrder?.total_ordered_qty}
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="row">
                  <strong>Received Total</strong>
                </div>
                <div className="row">
                  <label className="form-label fw-bold texttheme"></label>
                </div>
              </div> */}
            </div>
            <hr></hr>
            <Table data={purchaseOrderItems} columns={COLUMNS} />
          </div>
        </div>
        <ToastContainer position="bottom-right" hideProgressBar />
      </MainPanel>
    </>
  );
};

export default EditPurchaseOrder;
