const FormInput = ({ label, onBlur, onChange, value, ...input }) => {
  return (
    <fieldset>
      <label className="lblrequired lbltheme" htmlFor="name">
        {label}
      </label>
      <input
        {...input}
        className="form-control"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
  );
};

export default FormInput;
