import React, { useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import useHttp from "./hooks/useHttp";
import Navigation from "./components/template/navigation";
import Ingredients from "./pages/ingredients/Ingredients";
import Suppliers from "./pages/suppliers/Suppliers";
import SupplierDetail from "./pages/suppliers/SupplierDetail";
import AddSupplierPage from "./pages/suppliers/AddSupplierPage";
import Recipes from "./pages/recipes/Recipes";
import EditRecipe from "./pages/recipes/EditRecipe";
import Home from "./pages/Home";
import RegisterUser from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import PrivateRoutes, {
  PublicRoutes,
  PermissionForRoute,
} from "./utils/PrivateRoutes";
import FourOFour from "./pages/FourOFour";
import { EmailVerified, VerifyEmail } from "./pages/auth/ConfirmEmail";
import Users from "./pages/users/Users";
import AddUser from "./pages/users/AddUser";
import EditUser from "./pages/users/EditUser";
import Roles from "./pages/roles/Roles";
import AddPermissionToRole from "./pages/roles/AddPermissionToRole";
import AuthContext from "./store/LoginContext";
import MenuItems from "./pages/menuItems/MenuItems";
import EditMenuItem from "./pages/menuItems/EditMenuItem";
import RequestPasswordReset, {
  ResetPassword,
} from "./pages/auth/PasswordReset";
import Sales from "./pages/saleshistory/Sales";
import EditSale from "./pages/saleshistory/EditSale";
import Inventory from "./pages/inventory/Inventory";
import InventoryCount from "./pages/inventory/InventoryCount";
import UpdateInventoryCount from "./pages/inventory/UpdateInventoryCount";
import ViewInventoryCount from "./pages/inventory/ViewInventoryCount";
import EditIngredientNewPage from "./pages/ingredients/EditIngredientNewPage";
import Import from "./pages/imports/Import";
import GenerateInventoryReOrder from "./pages/order/GenerateInventoryReOrder";
import ShoppingCart from "./pages/shoppingCart/ShoppingCart";
import PurchaseOrder from "./pages/purchaseorder/PurchaseOrder";
import EditPurchaseOrder from "./pages/purchaseorder/EditPurchaseOrder";

function App() {
  const [_, handleHttpRequest] = useHttp();
  const { authToken, storeRolesAndPermissions, updateNotification } =
    useContext(AuthContext);

  const getRolesAndPermissions = async (user_id) => {
    const response = await handleHttpRequest({
      page: "user-role/" + user_id,
      method: "GET",
    });
    if (!response.status) {
      storeRolesAndPermissions(response.role.name, response.permissions);
    }
  };
  const getNotification = async () => {
    const notificationResponse = await handleHttpRequest({
      page: "cart-qty",
      method: "GET",
    });
    !notificationResponse.status && updateNotification(notificationResponse);
  };

  useEffect(() => {
    const user = authToken.user_id; //localStorage.getItem("user");
    if (user) {
      getRolesAndPermissions(user);
      getNotification();
    }
  }, []);

  return (
    <div className="App">
      <Navigation />
      <Routes>
        {/* Public Route without <Navigation /> */}
        <Route element={<PublicRoutes />}>
          <Route path="/register" element={<RegisterUser />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verifiy-email" element={<VerifyEmail />} />
          <Route path="/email-verified" element={<EmailVerified />} />
          <Route path="/forgot-password" element={<RequestPasswordReset />} />
          <Route path="/reset-password/" element={<ResetPassword />} />
        </Route>
        {/* PrivateRoutes */}
        <Route element={<PrivateRoutes />}>
          <Route element={<PermissionForRoute permission="view ingredient" />}>
            <Route path="/ingredients" element={<Ingredients />} />

            <Route path="/inventorycount" element={<Inventory />} />
            <Route path="/inventory-count/:id" element={<InventoryCount />} />
            <Route
              path="/update-inventory-count/:id"
              element={<UpdateInventoryCount />}
            />
            <Route
              path="/view-inventory-count/:id"
              element={<ViewInventoryCount />}
            />
          </Route>
          <Route element={<PermissionForRoute permission="view supplier" />}>
            <Route path="/suppliers" element={<Suppliers />} />
            <Route path="/supplier-detail/:id" element={<SupplierDetail />} />
          </Route>
          <Route element={<PermissionForRoute permission="add supplier" />}>
            <Route path="/addsupplier" element={<AddSupplierPage />} />
          </Route>
          <Route element={<PermissionForRoute permission="edit ingredient" />}>
            <Route
              path="/edit-ingredient/:id"
              element={<EditIngredientNewPage />}
            />
          </Route>
          <Route element={<PermissionForRoute permission="view recipe" />}>
            <Route path="/recipes" element={<Recipes />} />
          </Route>
          <Route element={<PermissionForRoute permission="edit recipe" />}>
            <Route path="/edit-recipe/:id" element={<EditRecipe />} />
          </Route>
          <Route element={<PermissionForRoute permission="view user" />}>
            <Route path="/users" element={<Users />} />
          </Route>
          <Route element={<PermissionForRoute permission="add user" />}>
            <Route path="/add-user" element={<AddUser />} />
          </Route>
          <Route element={<PermissionForRoute permission="edit user" />}>
            <Route path="/edit-user/:id" element={<EditUser />} />
          </Route>
          <Route element={<PermissionForRoute permission="view role" />}>
            <Route path="/roles" element={<Roles />} />
            <Route
              path="/role-permissions/:id"
              element={<AddPermissionToRole />}
            />
          </Route>

          <Route element={<PermissionForRoute permission="view menu item" />}>
            <Route path="/menuitems" element={<MenuItems />} />
            <Route path="/edit-menu-item/:id" element={<EditMenuItem />} />
          </Route>
          <Route element={<PermissionForRoute permission="view sales" />}>
            <Route path="/saleshistory" element={<Sales />} />
            <Route path="/edit-sale/:id" element={<EditSale />} />
            {/* //saleshistory /edit-sale/:id */}
          </Route>

          <Route
            path="/generateorders"
            element={<GenerateInventoryReOrder />}
          />
          <Route path="/imports" element={<Import />} />
          <Route path="/carts" element={<ShoppingCart />} />
          <Route path="/orders" element={<PurchaseOrder />} />
          <Route path="/edit-order-items/:id" element={<EditPurchaseOrder />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/logout" element={<Login />} /> */}
        </Route>

        <Route path="*" element={<FourOFour />} />
      </Routes>
    </div>
  );
}

export default App;
