import React, { useRef, useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "bootstrap";
import Notification from "../../components/Notification";
import useHttp from "../../hooks/useHttp";
import { useParams } from "react-router-dom";
import MainPanel from "../../components/MainPanel";

const EditUser = () => {
  const [_, handleHttpRequest] = useHttp();
  const { id: user_id } = useParams();

  const [roles, setRoles] = useState([]);
  const [registerSuccess, setRegisterSuccess] = useState();
  const [registerMessage, setRegisterMessage] = useState();
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    role_id: "",
  });

  const notificationStatus = useRef();
  const notificationMessage = useRef();
  const toastRef = useRef();
  var [toast, setToast] = useState(false);
  const [toastUpdated, setToastUpdated] = useState(false);
  useEffect(() => {
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);
    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast, { delay: 4000, autohide: true });
      // hide after init
      bsToast.hide();
      setToast(false);
    } else {
      // toggle
      toast ? bsToast.show() : bsToast.hide();
      // setToast(state => !state);
    }
  }, [toastUpdated, setToastUpdated]);

  const editUseHandler = async (values) => {
    try {
      const response = await handleHttpRequest({
        page: "user/edit/" + user_id,
        method: "PUT",
        data: values,
      });
      // console.log(response);
      if (!response.status) {
        //notifiy user is added.
        // setRegisterSuccess("User Updated successfully.");
        notificationMessage.current = response;
        notificationStatus.current = 200;
        setToast(true);
        setToastUpdated((state) => !state);
      } else {
        // setRegisterMessage(response.data.message);
        notificationMessage.current = "Unable to update user.";
        notificationStatus.current = 400;
        setToast(true);
        setToastUpdated((state) => !state);
      }
    } catch (err) {
      console.error("Login Error: ", err);
      // setRegisterMessage("Unable to update user, please try again.");
      notificationMessage.current = "Unable to update user.";
      notificationStatus.current = 500;
      setToast(true);
      setToastUpdated((state) => !state);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("User name is required")
        .max(100, "User name is too long"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email address is required."),
      role_id: Yup.number().required("Role must be assigned to use."),
      // .required(
      //   "Select a role for the user."
      // )
    }),
    onSubmit: async (values) => {
      editUseHandler(values);
      // formik.resetForm();
    },
  });

  const getUser = async () => {
    const response = await handleHttpRequest({
      page: "user/" + user_id,
      method: "GET",
    });
    if (!response.status) {
      // console.log("edit user", response);
      setInitialValues({
        name: response.name,
        email: response.email,
        role_id: response.roles.length != 0 ? response.roles[0].id : 0,
      });
    }
  };
  const getRoles = async () => {
    const response = await handleHttpRequest({
      page: "roles",
      method: "GET",
    });
    if (!response.status) {
      setRoles(response);
    }
  };
  useEffect(() => {
    getUser();
    getRoles();
  }, []);

  return (
    <MainPanel>
      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">Update user</h4>
          </div>
        </div>
        <div className="card-body">
          {/* {console.log("REgister message .. ", registerMessage)} */}
          {registerSuccess ? (
            <div class="alert alert-primary" role="alert">
              {registerSuccess}
            </div>
          ) : null}
          {registerMessage ? (
            <div class="alert alert-danger" role="alert">
              {registerMessage}
            </div>
          ) : null}
          <form class="" onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="row g-3">
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="name">
                      User Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="email">
                      Email *
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="email">
                      Role *
                    </label>

                    <select
                      class="form-select"
                      name="role_id"
                      id="role_id"
                      value={formik.values.role_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {/* value={formik.values.role_id} onChange={formik.handleChange} onBlur={formik.handleBlur}> */}
                      <option selected>Select Roles</option>
                      {roles == undefined
                        ? ""
                        : roles.length == 0
                        ? "No data"
                        : roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <span>
                  {formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null}
                </span>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <button type="submit" className="btn btntheme">
                  Update User
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Notification
        toastRef={toastRef}
        toast={toast}
        setToast={setToast}
        notificationStatus={notificationStatus.current}
      >
        {notificationMessage.current}
      </Notification>
    </MainPanel>
  );
};

export default EditUser;
