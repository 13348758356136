import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import SuppliersList from "../suppliers/supplierslist";
import units from "../../utils/units";
import useHttp from "../../hooks/useHttp";
import useConversion from "../../hooks/useConversion";
import { FaList } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import style from "../../assets/style/modal.module.css";

export const AddSupplierIngredientModal = ({
  ingredient_id,
  saveClick,
  onClick,
}) => {
  const [hasError, handleHttpRequest] = useHttp();

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const addSupplierIngredient = async (values) => {
    const response = await handleHttpRequest({
      page: "supplieringredient/add",
      method: "POST",
      data: {
        ...values,
        use_for_last_price: useLastPrice,
      },
    });

    if (!response.status) {
      saveClick(response, 200);
    } else {
      saveClick(response.data, response.status);
    }
  };

  const [showConvFac, setShowConvFac] = useState(false);
  const [IsStandardUnit, convertWeight] = useConversion();

  const [ispackUnitSize, setPackUnitSize] = useState(1);
  const [ispackUnitsMeas, setPackUnitsMeas] = useState("");
  const [ispacksPerSoldBy, setPacksPerSoldBy] = useState(1);
  const [isPricePerSoldBy, setPricePerSoldBy] = useState(0.0);
  const [isPricePerItem, setPricePerItem] = useState(0.0);
  const pricePerItem = useRef(0); //price per default item
  const pricePerSoldBy = useRef(0.0);
  const packsPerSoldBy = useRef(1);
  const packUnitSize = useRef(1);
  const packUnitsMeas = useRef("");
  const dfltUnits = useRef();
  const convFactor = useRef(1);

  const [initialValues, setInitialValues] = useState({});
  const getIngredient = async (id) => {
    // const response = await handleHttpRequest("ingredient/" + id);
    const response = await handleHttpRequest({
      page: "ingredient/" + id,
      method: "GET",
    });
    if (!hasError) {
      dfltUnits.current = response.message.default_unit_measure;
      setInitialValues({
        ingredient_id: id,
        supplier_id: "",
        supplier_item_number: "",
        supplier_item_desc: response.message.name,
        min_order_qty: "",
        sold_by: "",
        price_per_sold_by: 0.0,
        packs_per_sold_by: 1,
        pack_unit_measure: "",
        pack_unit_size: 1,
        pack_unit_desc: "",
        price_per_item: 0,
        price_opt: "op",
        price_opt_price: 0.0,
        price_opt_measure: "",
        conv_factor: 1,
      });
    }
  };
  useEffect(() => {
    getIngredient(ingredient_id.current);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      ingredient_id: Yup.number().required("Ingredient is required."),
      supplier_id: Yup.number().required(
        "Select a supplier for the Ingredient."
      ),
      supplier_item_number: Yup.string().max(100, "Item number is too long"),
      supplier_item_desc: Yup.string()
        .required("Supplier item description is required")
        .max(100, "Description is too long"),
      min_order_qty: Yup.number(),
      sold_by: Yup.string().max(100, "Name is too long"),
      price_per_sold_by: Yup.number(), //.required("Price per sold by is required"),
      packs_per_sold_by: Yup.number(), //.required("Pack per sold by is required"),
      pack_unit_measure: Yup.string().required("Pack unit measure is required"),
      pack_unit_size: Yup.number().required("Pack unit size is required"),
      pack_unit_desc: Yup.string().max(100, "Description is too long"),
      //price_opt: Yup.string().required("Supplier Name is required").max(100, "Name is too long"),
      price_opt_price: Yup.number(),
      price_opt_measure: Yup.string().max(100, "Name is too long"),
      conv_factor: Yup.number().positive(),
    }),
    onSubmit: (values) => {
      values.sold_by = values.sold_by == "" ? "case" : values.sold_by;
      addSupplierIngredient(values);
      formik.resetForm();
    },
  });

  const calculatePricePerItem = () => {
    setPricePerSoldBy(formik.values.price_per_sold_by);
    setPacksPerSoldBy(formik.values.packs_per_sold_by);
    setPackUnitSize(formik.values.pack_unit_size);
    setPackUnitsMeas(formik.values.pack_unit_measure);
    convFactor.current = formik.values.conv_factor;

    //Units are the same, no conversion needed
    if (dfltUnits.current == formik.values.pack_unit_measure) {
      setPricePerItem(
        parseFloat(
          formik.values.price_per_sold_by /
            (formik.values.packs_per_sold_by * formik.values.pack_unit_size)
        ).toFixed(2)
      ); //packUnitSize.current
      setShowConvFac(false);
    } // else do conversion
    else {
      if (
        IsStandardUnit(dfltUnits.current) !== "Y" ||
        IsStandardUnit(formik.values.pack_unit_measure) !== "Y"
      ) {
        setShowConvFac(true);
        //Non-standard unit, like Each, was selected
        setPricePerItem(
          parseFloat(
            formik.values.price_per_sold_by / convFactor.current
          ).toFixed(2)
        );
      } else {
        setShowConvFac(false);
        let dfltUnitQty = convertWeight(
          formik.values.pack_unit_measure,
          formik.values.pack_unit_size,
          formik.values.packs_per_sold_by,
          dfltUnits.current
        ); //packUnitSize.current
        setPricePerItem(
          parseFloat(formik.values.price_per_sold_by / dfltUnitQty).toFixed(2)
        );
      }
    }
    formik.values.price_per_item = isPricePerItem; //pricePerItem.current;
    return isPricePerItem; //pricePerItem.current;
  };

  const handleBlur = (e) => {
    calculatePricePerItem();
  };

  const handleCalculate = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    // formik.handleChange(e);
    calculatePricePerItem(e);
  };

  const [byCase, setByCase] = useState("bycase");
  const setByHandler = (event) => {
    setByCase(event.target.value);
  };

  const [useLastPrice, setUseLastPrice] = useState(true);
  const setUseLastPriceHandler = () => {
    setUseLastPrice((state) => !state);
  };

  return (
    <>
      <div className={style["modal-overlay-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} mx-7`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5
                  className="modal-title text-uppercase"
                  id="staticBackdropLabel"
                >
                  Supplier Option for {initialValues.supplier_item_desc}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <form className="" onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="row mt-4">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="supplier_id"
                          >
                            Supplier*
                          </label>
                          <SuppliersList
                            value={formik.values.supplier_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="supplier_item_desc"
                          >
                            Order Item Description*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="supplier_item_desc"
                            name="supplier_item_desc"
                            value={formik.values.supplier_item_desc}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-3">
                        <fieldset>
                          <label
                            htmlFor="supplier_item_number"
                            className="lbltheme"
                          >
                            Order Item #
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="supplier_item_number"
                            name="supplier_item_number"
                            value={formik.values.supplier_item_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-3">
                        <fieldset>
                          <label htmlFor="min_order_qty" className="lbltheme">
                            Minimum Order Quantity (by sellby)
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="min_order_qty"
                            name="min_order_qty"
                            value={formik.values.min_order_qty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div class="col-sm-3 form-check">
                        <fieldset>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="name"
                            name="name"
                            value="bycase"
                            checked={byCase == "bycase"}
                            onChange={setByHandler}
                          />
                          <label
                            className="form-check-label lbltheme"
                            htmlFor="flexRadioDefault1"
                          >
                            Supplier prices by{" "}
                            {formik.values.sold_by == ""
                              ? "case"
                              : formik.values.sold_by}
                          </label>
                        </fieldset>
                      </div>
                      <div class="col-sm-3 form-check">
                        <fieldset>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="name"
                            name="name"
                            value="bycustom"
                            checked={byCase == "bycustom"}
                            onChange={setByHandler}
                          />
                          <label
                            className="form-check-label lbltheme"
                            htmlFor="flexRadioDefault2"
                          >
                            Supplier prices by alternate unit
                          </label>
                        </fieldset>
                      </div>
                    </div>
                    {byCase == "bycustom" && (
                      <div className="row">
                        <div className="col-sm-3">
                          <fieldset>
                            <label
                              htmlFor="price_opt_price"
                              class="lblrequired lbltheme"
                            >
                              $Price per Alternate Unit*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="price_opt_price"
                              name="price_opt_price"
                              value={formik.values.price_opt_price}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </fieldset>
                        </div>
                        <div className="col-sm-3">
                          <fieldset>
                            <label
                              htmlFor="ingred-dfltunitmeas"
                              className="lblrequired lbltheme"
                            >
                              Unit of Measurement*
                            </label>
                            <select
                              className="form-select"
                              name="price_opt_measure"
                              id="price_opt_measure"
                              value={formik.values.price_opt_measure}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option selected>Select Measurement</option>
                              {units.map((unit) => (
                                <option key={unit.id} value={unit.unit}>
                                  {unit.name}
                                </option>
                              ))}
                            </select>
                          </fieldset>
                        </div>
                      </div>
                    )}
                    <hr></hr>

                    <div className="row">
                      <div className="col-sm-2">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="packs_per_sold_by"
                          >
                            #Packs per case*
                          </label>
                          <input
                            type="number"
                            step="1"
                            className="form-control"
                            id="packs_per_sold_by"
                            name="packs_per_sold_by"
                            value={formik.values.packs_per_sold_by}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-2">
                        <fieldset>
                          <label
                            htmlFor="pack_unit_size"
                            className="lbltheme lblrequired"
                          >
                            #Pack Unit Size*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="pack_unit_size"
                            name="pack_unit_size"
                            value={formik.values.pack_unit_size}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-2">
                        <fieldset>
                          <label
                            htmlFor="ingred-dfltunitmeas"
                            className="lblrequired lbltheme"
                          >
                            Pack Unit Measurement*
                          </label>
                          <select
                            className="form-select"
                            name="pack_unit_measure"
                            id="pack_unit_measure"
                            value={formik.values.pack_unit_measure}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          >
                            <option selected>Select Measurement</option>
                            {units.map((unit) => (
                              <option key={unit.id} value={unit.unit}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>
                      <div className="col-sm-2">
                        <fieldset>
                          <label htmlFor="pack_unit_desc" className="lbltheme">
                            Pack Unit Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="pack_unit_desc"
                            name="pack_unit_desc"
                            value={formik.values.pack_unit_desc}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-2">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="price_per_sold_by"
                          >
                            $Price per case*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="price_per_sold_by"
                            name="price_per_sold_by"
                            value={formik.values.price_per_sold_by}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-2">
                        <fieldset>
                          <label htmlFor="sold_by" className="lbltheme">
                            Sells By Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="sold_by"
                            name="sold_by"
                            value={formik.values.sold_by}
                            onChange={handleCalculate}
                            onBlur={handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    {showConvFac && formik.values.pack_unit_measure != "" && (
                      <div className="row">
                        <div className="col-sm-3">
                          <label
                            htmlFor="conv_factor"
                            class="lblrequired lbltheme"
                          >
                            Conversion Factor*:
                          </label>
                          <div class="input-group mb-3">
                            <span class="input-group-text">
                              {ispacksPerSoldBy == 1
                                ? "(" +
                                  ispackUnitSize +
                                  " " +
                                  ispackUnitsMeas +
                                  ")"
                                : ispacksPerSoldBy +
                                  "*" +
                                  " (" +
                                  ispackUnitSize +
                                  " " +
                                  ispackUnitsMeas +
                                  ")"}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              id="conv_factor"
                              name="conv_factor"
                              value={formik.values.conv_factor}
                              onChange={formik.handleChange}
                              onBlur={handleBlur}
                            />
                            <span class="input-group-text">
                              {dfltUnits.current}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row mt-4">
                      <span className="lblrequired lbltheme fw-bold fst-italic">
                        {ispackUnitsMeas !== "" ? (
                          <>
                            {ispacksPerSoldBy == 1
                              ? "(" +
                                ispackUnitSize +
                                " " +
                                ispackUnitsMeas +
                                ")"
                              : ispacksPerSoldBy +
                                "*" +
                                " (" +
                                ispackUnitSize +
                                " " +
                                ispackUnitsMeas +
                                ")"}
                          </>
                        ) : (
                          <>Example: case = 4 * (5 lb packs)</>
                        )}
                        {" = $" +
                          isPricePerSoldBy +
                          " " +
                          formik.values.sold_by +
                          "( $" +
                          isPricePerItem +
                          " per " +
                          dfltUnits.current +
                          ")"}
                      </span>

                      <input
                        type="hidden"
                        className="form-control"
                        id="price_per_item"
                        name="price_per_item"
                        value={formik.values.price_per_item}
                      />

                      <input
                        type="hidden"
                        className="form-control"
                        id="price_opt"
                        name="price_opt"
                        value={formik.values.price_opt}
                      />

                      <input
                        type="hidden"
                        className="form-control"
                        id="ingredient_id"
                        name="ingredient_id"
                        value={formik.values.ingredient_id}
                      />
                    </div>

                    <div class="form-check form-switch">
                      <input
                        className="form-check-input ckbtheme"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={useLastPrice}
                        onChange={setUseLastPriceHandler}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckChecked"
                      >
                        Use for Ingredient's Last Paid Price per Default Unit
                      </label>
                    </div>
                  </div>
                </div>
                <div className={style["modal-footer"]}>
                  <div className="row mt-3">
                    <div id="qadd-ingred-errmsg">
                      {formik.errors.supplier_id
                        ? formik.errors.supplier_id
                        : formik.errors.supplier_item_desc
                        ? formik.errors.supplier_item_desc
                        : formik.errors.pack_unit_measure
                        ? formik.errors.pack_unit_measure
                        : null}
                    </div>
                  </div>

                  <div className="col d-flex align-self-end justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-link btn-just-icon"
                      id="SaveIngred"
                      name="SaveIngred"
                      title="Save"
                    >
                      <IoIosSave className="save-icon" />
                    </button>
                  </div>
                  {/* <button type="submit" className="btn btn-primary">Save</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddSupplierIngredient = (props) => {
  return ReactDOM.createPortal(
    <AddSupplierIngredientModal
      ingredient_id={props.ingredient_id}
      saveClick={props.saveClick}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};
export default AddSupplierIngredient;
