import React, { useEffect, useState } from "react";
import Suppliers from "./Suppliers";
import MainPanel from "../../components/MainPanel";

const AddSupplierPage=()=>{
    return(
        <MainPanel>
            <div className="card">
                <div className="card-header text-bg-secondary">
                    <div className="row">
                        <h4 className="card-title">
                            Add SUPPLIERS
                        </h4>					  
                    </div>
                </div>	
                <div className="card-body" style={{marginRight:"20em",marginLeft:"2em"}}>
                <div className="toolbar"></div>
                <form class="row g-3">
                    <div className="row mt-4">
                        <div class="col-md-8">
                            <label for="inputEmail4" className="lblrequired ">Supplier Name*</label>
                            <input type="text" class="form-control" id="inputEmail4" />
                        </div>
                        <div class="col-md-4">
                            <label for="inputEmail4" className="lblrequired ">Account Number*</label>
                            <input type="text" class="form-control" id="inputEmail4" />
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6">
                            <label for="inputEmail4" class="form-label">Email</label>
                            <input type="email" class="form-control" id="inputEmail4" />
                        </div>
                        <div class="col-md-6">
                            <label for="inputEmail4" class="form-label">Phone</label>
                            <input type="text" class="form-control" id="inputEmail4" />
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div class="col-md-8">
                            <label for="inputEmail4" class="form-label">Business Address</label>
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                        </div>
                    </div>


                    <div className="row">
                        <div class="col-md-8">
                            <label for="inputEmail4" class="form-label">Personalized Notes</label>
                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div class="col-md-8">
                            <label for="inputEmail4" className="lblrequired ">Sale's Representative</label>
                            <input type="text" class="form-control" id="inputEmail4" />
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-4">
                            <label for="inputEmail4" class="form-label">Email</label>
                            <input type="email" class="form-control" id="inputEmail4" />
                        </div>
                        <div class="col-md-4">
                            <label for="inputEmail4" class="form-label">Phone</label>
                            <input type="text" class="form-control" id="inputEmail4" />
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div class="col-md-8">
                            <label for="inputEmail4" className="lblrequired ">AR</label>
                            <input type="text" class="form-control" id="inputEmail4" />
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-4">
                            <label for="inputEmail4" class="form-label">Email</label>
                            <input type="email" class="form-control" id="inputEmail4" />
                        </div>
                        <div class="col-md-4">
                            <label for="inputEmail4" class="form-label">Phone</label>
                            <input type="text" class="form-control" id="inputEmail4" />
                        </div>
                    </div>
                    
                    <div className="row mt-4">
                        <div class="col-md-8">
                            <button className="btn btn-primary">Save</button>
                        </div>
                    </div>

                </form>
                </div> 					
            </div>
            
        </MainPanel>
    )
}

export default AddSupplierPage;