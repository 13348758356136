import { useEffect, useState, useRef } from "react";
import useHttp from "../../hooks/useHttp";
import AddRecipe from "./AddRecipe";
import MainPanel from "../../components/MainPanel";
import Table from "../../components/Table";
import Notification from "../../components/Notification";
import { Toast } from "bootstrap";
import { FaRegEdit, FaPlus, FaList, FaFileImport } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import DeleteModal from "../DeleteModal";
import ImportRecipes from "../imports/ImportRecipes";
// import ImportRecipeItems from "../imports/ImportRecipeItems";

const Recipes = () => {
  const [hasError, handleHttpRequest] = useHttp();
  const [recipes, setRecipes] = useState([]);

  const [beUpdated, setBEUpdated] = useState(false);
  const [toastUpdated, setToastUpdated] = useState(false);

  const notificationStatus = useRef();
  const notificationMessage = useRef();

  const toastRef = useRef();
  var [toast, setToast] = useState(false);

  useEffect(() => {
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);
    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast, { delay: 4000, autohide: true });
      // hide after init
      bsToast.hide();
      setToast(false);
    } else {
      // toggle
      toast ? bsToast.show() : bsToast.hide();
      // setToast(state => !state);
    }
  }, [toastUpdated, setToastUpdated]);

  const getRecipes = async () => {
    // const response = await handleHttpRequest('recipes');
    const response = await handleHttpRequest({
      page: "recipes",
      method: "GET",
    });
    if (!hasError) {
      setRecipes(response);
    }
  };

  useEffect(() => {
    getRecipes();
  }, [beUpdated]);

  const COLUMNS = [
    {
      Header: "Recipe Name",
      accessor: "name",
    },
    {
      Header: "Recipe Yields",
      accessor: "recipe_yeilds",
      // Cell: ({value}) => formatPhoneNumber(value)
    },
    {
      Header: "Recipe Cost",
      accessor: "recipe_cost",
      align: "right",
      Cell: ({ value }) => "$" + value.toFixed(2),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <div>
          <button
            className="btn btn-link btn-just-icon icontheme editcount"
            style={{ color: "#FF7300" }}
          >
            <Link to={`/edit-recipe/${cell.value}`} className="themecolor">
              <FaRegEdit />{" "}
            </Link>
          </button>
          <button
            className="btn btn-link btn-just-icon iconthemedel delcount"
            style={{ color: "#676767" }}
            onClick={() => {
              openDeleteModal(cell.value);
            }}
          >
            <RiDeleteBin6Line />
          </button>
        </div>
      ),
    },
  ];

  const recipeId = useRef(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const openAddModal = () => {
    setIsAddModalOpen((state) => !state);
  };
  const saveRecipe = (response) => {
    setIsAddModalOpen(false);
    notificationMessage.current = response;
    notificationStatus.current = 200;
    setToast(true);
    setToastUpdated((state) => !state);
    setBEUpdated((state) => !state);
  };

  /* Delete Modal */
  const deleteIdIs = useRef(0);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const openDeleteModal = (id) => {
    deleteIdIs.current = id;
    setIsDeleteModalOpen((state) => !state);
  };
  const deleteHandler = (response) => {
    // console.log(response);
    setIsDeleteModalOpen(false);
    notificationMessage.current = response[0];
    notificationStatus.current = response[1];
    setToast(true);
    setToastUpdated((state) => !state);
    setBEUpdated((state) => !state);
  };

  /* Import Modal */
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const toggleImport = (type) => {
    setIsImportModalOpen((state) => !state);
  };
  const handleImportResponse = (response) => {
    setIsImportModalOpen(false);
    // toast.info(response);
    // setIsSalesUpdated((state) => !state);
  };
  const handleErrorResponse = (message) => {
    setIsImportModalOpen(false);
    // toast.error(message);
  };

  /* Import Modal */
  // const [isImportRecipeItemsModalOpen, setIsImportRecipeItemsModalOpen] =
  //   useState(false);
  // const toggleRecipeItemsImport = (type) => {
  //   setIsImportRecipeItemsModalOpen((state) => !state);
  // };
  // const handleRecipeItemsImportResponse = (response) => {
  //   setIsImportRecipeItemsModalOpen(false);
  //   // toast.info(response);
  //   // setIsSalesUpdated((state) => !state);
  // };
  // const handleRecipeItemsErrorResponse = (message) => {
  //   setIsImportRecipeItemsModalOpen(false);
  //   // toast.error(message);
  // };

  return (
    <>
      <MainPanel>
        {isImportModalOpen && (
          <ImportRecipes
            onClose={toggleImport}
            onImport={handleImportResponse}
            onError={handleErrorResponse}
          />
        )}
        {/* {isImportRecipeItemsModalOpen && (
          <ImportRecipeItems
            onClose={toggleRecipeItemsImport}
            onImport={handleRecipeItemsImportResponse}
            onError={handleRecipeItemsErrorResponse}
          />
        )} */}
        {
          isAddModalOpen && (
            <AddRecipe saveClick={saveRecipe} onClick={openAddModal} />
          )
          //recipe_id={recipeId}
        }
        {isdeleteModalOpen && (
          <DeleteModal
            deleteId={deleteIdIs.current}
            onClick={openDeleteModal}
            onDelete={deleteHandler}
            item="recipe"
            name="recipe"
          />
        )}
        <div className="card">
          <div className="card-header text-bg-secondary">
            <div className="row">
              <h4 className="card-title">
                RECIPES
                <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddRecipe"
                  title="New Recipe"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={openAddModal}
                >
                  <FaPlus />
                </button>
                {/* <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddRecipe"
                  title="New Recipe"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={toggleImport}
                >
                  <FaFileImport />
                </button> */}
                {/* <button
                  type="button"
                  className="btn btn-outline-light ms-3"
                  name="AddRecipe"
                  title="New Recipe Items"
                  style={{ padding: "4px 8px 7px" }}
                  onClick={toggleRecipeItemsImport}
                >
                  <FaFileImport />
                </button> */}
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="toolbar"></div>
            <Table data={recipes} columns={COLUMNS} />
          </div>
        </div>
        <Notification
          toastRef={toastRef}
          toast={toast}
          setToast={setToast}
          notificationStatus={notificationStatus.current}
        >
          {notificationMessage.current}
        </Notification>
      </MainPanel>
    </>
  );
};

export default Recipes;
