//EditRecipeIngredient.js
import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import units from "../../utils/units";
import { IoIosSave } from "react-icons/io";
import SelectIngredients from "../ingredients/SelectIngredients";
import SelectRecipes from "../recipes/SelectRecipes";
import useConversion from "../../hooks/useConversion";

const EditRecipeIngredientModal = ({
  menuitem_name,
  ingRecId,
  menuitem_id,
  type,
  saveClick,
  cancleClick,
}) => {
  const [_, handleHttpRequest] = useHttp();
  const [initialValues, setInitialValues] = useState();

  const editItem = async (values) => {
    const response = await handleHttpRequest({
      page:
        type == "Recipe"
          ? "menuitem-recipe/edit/" + ingRecId
          : "menuitem-ingredient/edit/" + ingRecId,
      method: "PUT",
      data:
        type == "Recipe"
          ? {
              recipe_id: values.recipe_id,
              menu_item_id: menuitem_id,
              quantity: values.quantity,
              unit_measure: values.unit_measure,
            }
          : {
              ingredient_id: values.ingredient_id,
              menu_item_id: menuitem_id,
              quantity: values.quantity,
              unit_measure: values.unit_measure,
            },
    });
    if (!response.status) {
      saveClick(response, 200);
    } else {
      saveClick(response.data, response.status);
    }
  };
  const closeModal = () => {
    // formik.resetForm();
    cancleClick();
  };

  const getItem = async () => {
    const response = await handleHttpRequest({
      page:
        type == "Recipe"
          ? "menuitem-recipe/" + ingRecId
          : "menuitem-ingredient/" + ingRecId,
      method: "GET",
    });
    if (!response.status) {
      setInitialValues(response);
    }
  };
  useEffect(() => {
    getItem();
  }, []);

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Edit {type} of {menuitem_name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  {type == "Recipe" && (
                    <EditRecipe
                      initialValues={initialValues}
                      editItem={editItem}
                    />
                  )}
                  {type == "Ingredient" && (
                    <EditIngredient
                      initialValues={initialValues}
                      editItem={editItem}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditRecipe = ({
  initialValues = { recipe_id: "", unit_measure: "", quantity: "" },
  editItem,
}) => {
  const [unitMeasure, setUnitMeasure] = useState("");
  const [IsStandardUnit, convertWeight] = useConversion();
  const nonStandUnit = useRef({});
  const updateUnitMeasure = (value) => {
    setUnitMeasure(value);
    if (IsStandardUnit(value) != "Y" && value != "") {
      nonStandUnit.current = units.filter((unit) => unit.unit == value)[0];
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    // {recipe_id:initialValues.recipe_id,unit_measure: initialValues.unit_measure, quantity: initialValues.quantity }},
    validationSchema: Yup.object({
      recipe_id: Yup.number().required("Select a the Ingredient."),
      quantity: Yup.number()
        .typeError("Quantity must be a number")
        .required("Enter the quantity"),
      unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement."
      ),
    }),
    onSubmit: (values) => {
      editItem(values);
    },
  });

  useEffect(() => {
    updateUnitMeasure(formik.values.unit_measure);
  }, [formik.values.unit_measure]);

  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-sm-4">
          <fieldset>
            <label className="lblrequired lbltheme" htmlFor="name">
              Recipe*
            </label>
            <SelectRecipes
              value={formik.values.recipe_id}
              setUnitMeasure={updateUnitMeasure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="unit_measure" className="lblrequired lbltheme">
              Measurement* {formik.values.unit_measure}
            </label>
            <select
              className="form-select"
              name="unit_measure"
              id="unit_measure"
              value={formik.values.unit_measure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option selected>Select Measurement</option>
              {IsStandardUnit(unitMeasure) == "Y" ? (
                units.map((unit) => (
                  <option key={unit.id} value={unit.unit}>
                    {unit.name}
                  </option>
                ))
              ) : (
                <option
                  key={nonStandUnit.current.id}
                  value={nonStandUnit.current.unit}
                >
                  {nonStandUnit.current.name}
                </option>
              )}
            </select>
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="quantity" class="lblrequired lbltheme">
              Quantity
            </label>
            <input
              type="text"
              className="form-control"
              id="quantity"
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-1 d-flex align-self-end justify-content-end">
          <button
            type="submit"
            className="btn btn-link btn-just-icon"
            id="SaveIngred"
            name="SaveIngred"
            title="Save"
          >
            <IoIosSave className="save-icon" />
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div id="qadd-ingred-errmsg">
          {formik.errors.recipe_id
            ? formik.errors.recipe_id
            : formik.errors.unit_measure
            ? formik.errors.unit_measure
            : formik.errors.unit_size
            ? formik.errors.unit_size
            : null}
        </div>
      </div>
    </form>
  );
};

const EditIngredient = ({
  initialValues = { ingredient_id: "", quantity: "", unit_measure: "" },
  editItem,
}) => {
  const [unitMeasure, setUnitMeasure] = useState("");
  const [IsStandardUnit, convertWeight] = useConversion();
  const nonStandUnit = useRef({ id: "", unit: "", name: "" });
  const updateUnitMeasure = (value) => {
    setUnitMeasure(value);
    if (IsStandardUnit(value) != "Y" && value != "") {
      nonStandUnit.current = units.filter((unit) => unit.unit == value)[0];
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      ingredient_id: Yup.number().required("Select a the Ingredient."),
      quantity: Yup.number()
        .typeError("Quantity must be a number")
        .required("Enter the quantity"),
      unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement."
      ),
    }),
    onSubmit: (values) => {
      editItem(values);
    },
  });
  useEffect(() => {
    updateUnitMeasure(formik.values.unit_measure);
  }, [formik.values.unit_measure]);

  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-sm-4">
          <fieldset>
            <label className="lblrequired lbltheme" htmlFor="name">
              Ingredient*
            </label>
            <SelectIngredients
              setIngredient={(value) => console.log("")}
              value={formik.values.ingredient_id}
              setUnitMeasure={updateUnitMeasure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="unit_measure" className="lblrequired lbltheme">
              Measurement*
            </label>
            <select
              className="form-select"
              name="unit_measure"
              id="unit_measure"
              value={formik.values.unit_measure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option selected>Select Measurement</option>
              {IsStandardUnit(unitMeasure) == "Y" ? (
                units.map((unit) => (
                  <option key={unit.id} value={unit.unit}>
                    {unit.name}
                  </option>
                ))
              ) : (
                //nonStandUnit.current != undefined &&
                <option
                  key={nonStandUnit.current.id}
                  value={nonStandUnit.current.unit}
                >
                  {nonStandUnit.current.name}
                </option>
              )}
            </select>
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="quantity" class="lblrequired lbltheme">
              Quantity
            </label>
            <input
              type="text"
              className="form-control"
              id="quantity"
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-1 d-flex align-self-end justify-content-end">
          <button
            type="submit"
            className="btn btn-link btn-just-icon"
            id="SaveIngred"
            name="SaveIngred"
            title="Save"
          >
            <IoIosSave className="save-icon" />
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div id="qadd-ingred-errmsg">
          {formik.errors.ingredient_id
            ? formik.errors.ingredient_id
            : formik.errors.unit_measure
            ? formik.errors.unit_measure
            : formik.errors.unit_size
            ? formik.errors.unit_size
            : null}
        </div>
      </div>
    </form>
  );
};

const EditRecipeIngredient = (props) => {
  return ReactDOM.createPortal(
    <EditRecipeIngredientModal
      ingRecId={props.ingRecId}
      menuitem_id={props.menuitem_id}
      menuitem_name={props.menuitem_name}
      type={props.type}
      saveClick={props.saveClick}
      cancleClick={props.cancleClick}
    />,
    document.getElementById("modal")
  );
};
export default EditRecipeIngredient;
