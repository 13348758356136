import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import units from "../../utils/units";
import Category from "../categories/Category";
import style from "../../assets/style/modal.module.css";
import { FaList } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";

export const AddIngredientModal = ({
  saveClick,
  onClick,
  onCategoryOpen,
  categorySaved,
}) => {
  const closeModal = () => {
    formik.resetForm();
    onClick();
  };
  const saveCloseModal = (saveStatus) => {
    saveClick(saveStatus);
  };
  const [hasError, handleHttpRequest] = useHttp();
  const addIngredient = async (values) => {
    const response = await handleHttpRequest({
      page: "ingredient/add",
      method: "POST",
      data: values,
    });
    if (!hasError) {
      saveCloseModal({ status: response.status, message: response.message });
    } else {
      closeModal();
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      default_unit_measure: "",
      default_unit_price: "",
      category_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(" Ingredient Name is required.")
        .max(100, "Ingredient name is too long"),
      default_unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement for the Ingredient."
      ),
      default_unit_price: Yup.number(
        "Default unit price must be a number"
      ).required("Last price is required."),
      category_id: Yup.number().required(
        "Select a category for the Ingredient."
      ),
    }),
    onSubmit: (values) => {
      addIngredient(values);
      formik.resetForm();
    },
  });

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add Ingredient
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  <form className="" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <fieldset>
                          <label
                            className="lblrequired lbltheme"
                            htmlFor="name"
                          >
                            Ingredient Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col-sm-3">
                        <fieldset>
                          <label htmlFor="category_id" className="lbltheme">
                            Ingredient Category
                          </label>
                          <Category
                            categorySaved={categorySaved}
                            value={formik.values.category_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                      <div className="col d-flex align-self-end">
                        <button
                          type="button"
                          className="btn btn-link btn-just-icon"
                          id="AddIngredCatPop"
                          name="AddIngredCatPop"
                          onClick={onCategoryOpen}
                          title="New Ingredient Category"
                        >
                          <FaList className="themecolor" />
                        </button>
                      </div>
                      <div className="col d-flex align-self-end justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-link btn-just-icon"
                          id="SaveIngred"
                          name="SaveIngred"
                          title="Save"
                        >
                          <IoIosSave className="save-icon" />
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <fieldset>
                          <label
                            htmlFor="ingred-dfltunitmeas"
                            className="lblrequired lbltheme"
                          >
                            Default Unit of Measurement*
                          </label>
                          <select
                            className="form-select"
                            name="default_unit_measure"
                            id="default_unit_measure"
                            value={formik.values.default_unit_measure}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option selected>Select Measurement</option>
                            {units.map((unit) => (
                              <option key={unit.id} value={unit.unit}>
                                {unit.name}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                      </div>
                      <div className="col-sm-3">
                        <fieldset>
                          <label
                            htmlFor="default_unit_price"
                            class="lblrequired lbltheme"
                          >
                            Last Price per Default Unit*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="default_unit_price"
                            name="default_unit_price"
                            value={formik.values.default_unit_price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div id="qadd-ingred-errmsg">
                        {formik.errors.name
                          ? formik.errors.name
                          : formik.errors.default_unit_measure
                          ? formik.errors.default_unit_measure
                          : formik.errors.default_unit_price
                          ? formik.errors.default_unit_price
                          : formik.errors.category_id
                          ? formik.errors.category_id
                          : null}
                      </div>
                    </div>
                  </form>
                  <div>
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">
                          <button
                            type="button"
                            className="btn btn-link btn-just-icon"
                            id="AddIngredCatPop"
                            name="AddIngredCatPop"
                            title="New Ingredient Category"
                          >
                            <FaList />
                          </button>
                          SUPPLIER INGREDIENTS
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddIngredient = (props) => {
  return ReactDOM.createPortal(
    <AddIngredientModal
      categorySaved={props.categorySaved}
      onCategoryOpen={props.onCategoryOpen}
      saveClick={props.saveClick}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};
export default AddIngredient;
