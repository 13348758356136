import React, { useRef, useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "bootstrap";
import Notification from "../../components/Notification";
import useHttp from "../../hooks/useHttp";
import MainPanel from "../../components/MainPanel";

const AddUser = () => {
  const [_, handleHttpRequest] = useHttp();
  const [registerMessage, setRegisterMessage] = useState();
  const [roles, setRoles] = useState([]);

  const notificationStatus = useRef();
  const notificationMessage = useRef();
  const toastRef = useRef();
  var [toast, setToast] = useState(false);
  const [toastUpdated, setToastUpdated] = useState(false);
  useEffect(() => {
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);
    if (!bsToast) {
      // initialize Toast
      bsToast = new Toast(myToast, { delay: 4000, autohide: true });
      // hide after init
      bsToast.hide();
      setToast(false);
    } else {
      // toggle
      toast ? bsToast.show() : bsToast.hide();
      // setToast(state => !state);
    }
  }, [toastUpdated, setToastUpdated]);

  const addUserHandler = async (values) => {
    // try {
    const response = await handleHttpRequest({
      page: "user/add",
      method: "POST",
      data: values,
    });

    if (!response.status) {
      //notifiy user is added.
      notificationMessage.current = response;
      notificationStatus.current = 200;
      setToast(true);
      setToastUpdated((state) => !state);
    } else {
      // setRegisterMessage(response.data.message);
      notificationMessage.current = "Unable to add user.";
      notificationStatus.current = 400;
      setToast(true);
      setToastUpdated((state) => !state);
    }
    // } catch (error) {
    //   console.error("Add user Error: ", error);
    //   setRegisterMessage("Unable to add user, please try again.");
    // }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("User name is required")
        .max(100, "User name is too long"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email address is required."),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .max(100, "Password is too long")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&()*])/,
          "Must contain 8 characters, with minimum one uppercase, one lowercase, one number and a special case character."
        ),
      password_confirmation: Yup.string()
        .required("Password confirmation is required")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      role_id: Yup.number().required("Role must be assigned to use."),
    }),
    onSubmit: async (values) => {
      addUserHandler(values);
      // formik.resetForm();
    },
  });

  const getRoles = async () => {
    const response = await handleHttpRequest({
      page: "roles",
      method: "GET",
    });
    if (!response.status) {
      setRoles(response);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <MainPanel>
      <div className="card">
        <div className="card-header text-bg-secondary">
          <div className="row">
            <h4 className="card-title">Create a user</h4>
          </div>
        </div>
        <div className="card-body">
          {/* {console.log("REgister message .. ", registerMessage)} */}
          {registerMessage ? (
            <div class="alert alert-danger" role="alert">
              {registerMessage}
            </div>
          ) : null}
          <form class="" onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="row g-3">
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="name">
                      User Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="email">
                      Email *
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label
                      className="form-label lblrequired"
                      htmlFor="password"
                    >
                      Password *
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label
                      className="form-label lblrequired"
                      htmlFor="password_confirmation"
                    >
                      Confirm password *
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password_confirmation"
                      name="password_confirmation"
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label className="form-label lblrequired" htmlFor="email">
                      Role *
                    </label>

                    <select
                      class="form-select"
                      name="role_id"
                      id="role_id"
                      value={formik.values.role_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {/* value={formik.values.role_id} onChange={formik.handleChange} onBlur={formik.handleBlur}> */}
                      <option selected>Select Roles</option>
                      {roles == undefined
                        ? ""
                        : roles.length == 0
                        ? "No data"
                        : roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <span>
                  {formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : formik.touched.password_confirmation &&
                      formik.errors.password_confirmation
                    ? formik.errors.password_confirmation
                    : null}
                </span>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <button type="submit" className="btn btntheme">
                  Add User
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Notification
        toastRef={toastRef}
        toast={toast}
        setToast={setToast}
        notificationStatus={notificationStatus.current}
      >
        {notificationMessage.current}
      </Notification>
    </MainPanel>
  );
};

export default AddUser;
