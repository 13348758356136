import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import units from "../../utils/units";
import { FaRegEdit, FaPlus, FaList } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import SelectIngredients from "../ingredients/SelectIngredients";
import SelectRecipes from "../recipes/SelectRecipes";
import useConversion from "../../hooks/useConversion";

const AddRecipeIngredientModal = ({
  menuitem_name,
  menuitem_id,
  type,
  saveClick,
  cancleClick,
}) => {
  const [_, handleHttpRequest] = useHttp();

  const addItem = async (values) => {
    const response = await handleHttpRequest({
      page:
        type == "Recipe" ? "menuitem-recipe/add" : "menuitem-ingredient/add",
      method: "POST",
      data:
        type == "Recipe"
          ? {
              recipe_id: values.recipe_id,
              menu_item_id: menuitem_id,
              quantity: values.quantity,
              unit_measure: values.unit_measure,
            }
          : {
              ingredient_id: values.ingredient_id,
              menu_item_id: menuitem_id,
              quantity: values.quantity,
              unit_measure: values.unit_measure,
            },
    });
    if (!response.status) {
      saveClick(response, 200);
    } else {
      saveClick(response.data, response.status);
    }
  };
  const closeModal = () => {
    // formik.resetForm();
    cancleClick();
  };

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add {type} to {menuitem_name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  {type == "Recipe" && <AddRecipe addItem={addItem} />}
                  {type == "Ingredient" && <AddIngredient addItem={addItem} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddRecipe = ({ addItem }) => {
  const [unitMeasure, setUnitMeasure] = useState("");
  const [IsStandardUnit, convertWeight] = useConversion();
  const nonStandUnit = useRef({});
  const updateUnitMeasure = (value) => {
    setUnitMeasure(value);
    if (IsStandardUnit(value) != "Y") {
      nonStandUnit.current = units.filter((unit) => unit.unit == value)[0];
    }
  };
  const formik = useFormik({
    initialValues: {
      recipe_id: "",
      quantity: "",
      unit_measure: "",
    },
    validationSchema: Yup.object({
      recipe_id: Yup.number().required("Select a the Ingredient."),
      quantity: Yup.number()
        .typeError("Quantity must be a number")
        .required("Enter the quantity"),
      unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement."
      ),
    }),
    onSubmit: (values) => {
      addItem(values);
    },
  });
  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-sm-4">
          <fieldset>
            <label className="lblrequired lbltheme" htmlFor="name">
              Recipe*
            </label>
            <SelectRecipes
              value={formik.values.recipe_id}
              setUnitMeasure={updateUnitMeasure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="unit_measure" className="lblrequired lbltheme">
              Measurement*
            </label>
            <select
              className="form-select"
              name="unit_measure"
              id="unit_measure"
              value={formik.values.unit_measure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option selected>Select Measurement</option>
              {IsStandardUnit(unitMeasure) == "Y" ? (
                units.map((unit) => (
                  <option key={unit.id} value={unit.unit}>
                    {unit.name}
                  </option>
                ))
              ) : (
                <option
                  key={nonStandUnit.current.id}
                  value={nonStandUnit.current.unit}
                >
                  {nonStandUnit.current.name}
                </option>
              )}
            </select>
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="quantity" class="lblrequired lbltheme">
              Quantity
            </label>
            <input
              type="text"
              className="form-control"
              id="quantity"
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-1 d-flex align-self-end justify-content-end">
          <button
            type="submit"
            className="btn btn-link btn-just-icon"
            id="SaveIngred"
            name="SaveIngred"
            title="Save"
          >
            <IoIosSave className="save-icon" />
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div id="qadd-ingred-errmsg">
          {formik.errors.recipe_id
            ? formik.errors.recipe_id
            : formik.errors.unit_measure
            ? formik.errors.unit_measure
            : formik.errors.unit_size
            ? formik.errors.unit_size
            : null}
        </div>
      </div>
    </form>
  );
};

const AddIngredient = ({ addItem }) => {
  const [unitMeasure, setUnitMeasure] = useState("");
  const [IsStandardUnit, convertWeight] = useConversion();
  const nonStandUnit = useRef({});
  const updateUnitMeasure = (value) => {
    setUnitMeasure(value);
    if (IsStandardUnit(value) != "Y") {
      nonStandUnit.current = units.filter((unit) => unit.unit == value)[0];
    }
  };
  const formik = useFormik({
    initialValues: {
      ingredient_id: "",
      quantity: "",
      unit_measure: "",
    },
    validationSchema: Yup.object({
      ingredient_id: Yup.number().required("Select a the Ingredient."),
      quantity: Yup.number()
        .typeError("Quantity must be a number")
        .required("Enter the quantity"),
      unit_measure: Yup.string().required(
        "Select a Default Unit of Measurement."
      ),
    }),
    onSubmit: (values) => {
      addItem(values);
    },
  });
  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-sm-4">
          <fieldset>
            <label className="lblrequired lbltheme" htmlFor="name">
              Ingredient*
            </label>
            <SelectIngredients
              setIngredient={(value) => console.log("")}
              value={formik.values.ingredient_id}
              setUnitMeasure={updateUnitMeasure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="unit_measure" className="lblrequired lbltheme">
              Measurement*
            </label>
            <select
              className="form-select"
              name="unit_measure"
              id="unit_measure"
              value={formik.values.unit_measure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option selected>Select Measurement</option>
              {IsStandardUnit(unitMeasure) == "Y" ? (
                units.map((unit) => (
                  <option key={unit.id} value={unit.unit}>
                    {unit.name}
                  </option>
                ))
              ) : (
                <option
                  key={nonStandUnit.current.id}
                  value={nonStandUnit.current.unit}
                >
                  {nonStandUnit.current.name}
                </option>
              )}
            </select>
          </fieldset>
        </div>
        <div className="col-sm-3">
          <fieldset>
            <label htmlFor="quantity" class="lblrequired lbltheme">
              Quantity
            </label>
            <input
              type="text"
              className="form-control"
              id="quantity"
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </fieldset>
        </div>
        <div className="col-sm-1 d-flex align-self-end justify-content-end">
          <button
            type="submit"
            className="btn btn-link btn-just-icon"
            id="SaveIngred"
            name="SaveIngred"
            title="Save"
          >
            <IoIosSave className="save-icon" />
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div id="qadd-ingred-errmsg">
          {formik.errors.ingredient_id
            ? formik.errors.ingredient_id
            : formik.errors.unit_measure
            ? formik.errors.unit_measure
            : formik.errors.unit_size
            ? formik.errors.unit_size
            : null}
        </div>
      </div>
    </form>
  );
};

const AddRecipeIngredient = (props) => {
  return ReactDOM.createPortal(
    <AddRecipeIngredientModal
      menuitem_id={props.menuitem_id}
      type={props.type}
      menuitem_name={props.menuitem_name}
      saveClick={props.saveClick}
      cancleClick={props.cancleClick}
    />,
    document.getElementById("modal")
  );
};
export default AddRecipeIngredient;
