import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";

export const AddCategoryModal = ({ saveClick, onClick }) => {
  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const [hasError, handleHttpRequest] = useHttp();
  const addCategory = async (values) => {
    const response = await handleHttpRequest({
      page: "category/add",
      method: "POST",
      data: values,
    });
    if (!response.status) {
      saveClick(response, 200);
    } else {
      saveClick(response.data, response.status);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(" Ingredient Category Name is required")
        .max(100, "Name is too long"),
    }),
    onSubmit: (values) => {
      addCategory(values);
      formik.resetForm();
    },
  });

  return (
    <>
      <div className={style["modal-overlay-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-50`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary `}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  ADD NEW INGREDIENT CATEGORY
                </h5>
                <button
                  type="button"
                  className="btn-close dark-mode"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <form class="" onSubmit={formik.handleSubmit}>
                <div className={style["modal-content"]}>
                  <div className="modal-body row g-3">
                    <div className="col-md-6">
                      <label className="lblrequired lbltheme" htmlFor="name">
                        Ingredient Category*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className={style["modal-footer"]}>
                  <div>
                    {formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null}
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddCategory = (props) => {
  return ReactDOM.createPortal(
    <AddCategoryModal saveClick={props.saveClick} onClick={props.onClick} />,
    document.getElementById("modal")
  );
};
export default AddCategory;
