import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../hooks/useHttp";
import style from "../../assets/style/modal.module.css";
import { IoIosSave } from "react-icons/io";
import Table from "../../components/Table";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

export const ReceiveOrderModal = ({
  orderItemId,
  updatePurchaseOrder,
  onClick,
}) => {
  const [_, handleHttpRequest] = useHttp();
  const [initialValues, setInitialValues] = useState({
    receive_order_qty: "",
    receive_price: "",
    receive_date: "",
    receipt_number: "",
    purchase_order_item_id: orderItemId,
    id: null,
  });
  const [purchaseOrderItem, setPurchaseOrderItem] = useState();
  const [receivedOrders, setReceivedOrders] = useState([]);
  const [editReceiveOrder, setEditReceiveOrder] = useState(false);

  const closeModal = () => {
    formik.resetForm();
    onClick();
  };

  const getPurchaseOrder = async () => {
    const response = await handleHttpRequest({
      page: "purchaseorderitem/" + orderItemId,
      method: "GET",
    });
    if (!response.status) {
      console.log("purchase order", response);
      setPurchaseOrderItem(response);
    }
  };

  const getReceivedOrder = async () => {
    const response = await handleHttpRequest({
      page: "receiveorder-order/" + orderItemId,
      method: "GET",
    });
    if (!response.status) {
      //set initialValues
      setReceivedOrders(response);
    }
  };

  useEffect(() => {
    getPurchaseOrder();
    getReceivedOrder();
  }, []);

  const receiveOrder = async (values) => {
    if (values.id == null) {
      const response = await handleHttpRequest({
        page: "receiveorder",
        method: "POST",
        data: values,
      });
      console.log("receive order response: ", response);
      if (!response.status) {
        getReceivedOrder();
        updatePurchaseOrder();
      } else {
        // saveClick(response.data, response.status);
        //notification
        //refresh- the columns --table
      }
    } else {
      const response = await handleHttpRequest({
        page: "receiveorder/" + values.id,
        method: "PUT",
        data: values,
      });
      console.log("receive order update response: ", response);
      if (!response.status) {
        setEditReceiveOrder(false);
        // saveClick(response, 200);
        //notification
        getReceivedOrder();
        updatePurchaseOrder();
      } else {
        // saveClick(response.data, response.status);
        //notification
        //refresh- the columns --table
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      receive_order_qty: Yup.number(),
      receive_price: Yup.number(),
      receive_date: Yup.date(),
      receipt_number: Yup.string(),
    }),
    onSubmit: (values) => {
      receiveOrder(values);
      formik.resetForm();
      setInitialValues({
        receive_order_qty: "",
        receive_price: "",
        receive_date: "",
        receipt_number: "",
        purchase_order_item_id: orderItemId,
        id: null,
      });
    },
  });

  const openEditReceiveItem = (item) => {
    console.log("edit receive item: ", item); //receipt_number
    setInitialValues({
      receive_order_qty: item.receive_order_qty,
      receive_price: item.receive_price,
      receive_date: item.receive_date,
      receipt_number: item.receipt_number,
      purchase_order_item_id: orderItemId,
      id: item.id,
    });
    setEditReceiveOrder(true);
  };

  const deleteReceiveItem = async (item) => {
    const response = await handleHttpRequest({
      page: "receiveorder/" + item.id,
      method: "DELETE",
    });
    console.log("Delete receive order line item", response);
    if (!response.status) {
      getReceivedOrder();
      updatePurchaseOrder();
    } else {
      // onDelete([response.data, response.status]);
    }
  };

  const openDeleteReceiveItem = async (item) => {
    console.log("delete receive item: ", item);
    await new Promise((resolve) => {
      alert("Delete this receive record?");
      resolve();
    });
    deleteReceiveItem(item);
  };

  const COLUMNS = [
    {
      Header: "Received Date",
      accessor: "receive_date",
    },
    {
      Header: "Reciept #",
      accessor: "receipt_number",
    },
    {
      Header: "Received Qty",
      accessor: "receive_order_qty",
    },
    {
      Header: "Receiving price",
      accessor: "receive_price",
    },
    {
      Header: "Type",
      accessor: "type",
      disableSortBy: true,
      Cell: (cell) => (
        <span
          className={`badge rounded-pill ${
            (cell.value == "Recvd" && "text-bg-success") ||
            (cell.value == "Voided" && "text-bg-danger")
          }`}
          style={{ color: "#000" }}
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => (
        <>
          <div>
            <button
              className="btn btn-link btn-just-icon icontheme editcount themecolor"
              style={{ color: "#FF7300" }}
              onClick={() => {
                openEditReceiveItem(cell.row.original);
              }}
            >
              <FaRegEdit />{" "}
            </button>
            <button
              className="btn btn-link btn-just-icon icontheme "
              onClick={() => {
                openDeleteReceiveItem(cell.row.original);
              }}
            >
              <RiDeleteBin6Line />{" "}
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className={style["modal-overlay"]}></div>
      <div className={style["modal-card-wrapper"]}>
        <div className={`card  ${style["modal-card"]} w-75`} role="dialog">
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div
                className={` ${style["modal-header"]} text-bg-secondary`}
                data-bs-theme="dark"
              >
                <h5 className="modal-title" id="staticBackdropLabel">
                  LINE ITEM FOR{" "}
                  {purchaseOrderItem?.supplieringredient.supplier_item_desc}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className={style["modal-content"]}>
                <div className="modal-body row g-3">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mt-4">
                      <div className="col-md-3">
                        <strong>Item #</strong>
                        <div>
                          {
                            purchaseOrderItem?.supplieringredient
                              .supplier_item_number
                          }
                        </div>
                      </div>
                      <div className="col-md-3">
                        <strong>Item Description</strong>
                        <div>
                          {
                            purchaseOrderItem?.supplieringredient
                              .supplier_item_desc
                          }
                        </div>
                      </div>
                      <div className="col-md-3">
                        <strong>Qty ordered per sellby</strong>
                        <div>
                          {purchaseOrderItem?.ordered_qty}{" "}
                          {purchaseOrderItem?.supplieringredient.sold_by}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <strong>Description</strong>
                        <div>
                          {purchaseOrderItem &&
                            purchaseOrderItem?.supplieringredient
                              .packs_per_sold_by +
                              "*(" +
                              purchaseOrderItem?.supplieringredient
                                .pack_unit_size +
                              "" +
                              purchaseOrderItem?.supplieringredient
                                .pack_unit_measure +
                              ")"}
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <div className="col-sm-4">
                        <label
                          className="form-label lblrequired "
                          htmlFor="receive_date"
                        >
                          Received date*
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="receive_date"
                          name="receive_date"
                          value={formik.values.receive_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label
                          className="form-label lblrequired"
                          htmlFor="receipt_number"
                        >
                          Receipt number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="receipt_number"
                          name="receipt_number"
                          value={formik.values.receipt_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <label
                          className="form-label lblrequired "
                          htmlFor="receive_order_qty"
                        >
                          Quantity Received*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="receive_order_qty"
                          name="receive_order_qty"
                          value={formik.values.receive_order_qty}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label
                          className="form-label lblrequired"
                          htmlFor="receive_price"
                        >
                          Price per SellBy*
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="receive_price"
                          name="receive_price"
                          value={formik.values.receive_price}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label
                          className="form-label lblrequired"
                          htmlFor="receive_order_qty"
                        >
                          $Recvd Ext Total*
                        </label>
                        <div>
                          <span>
                            {formik.values.receive_price != ""
                              ? formik.values.receive_order_qty != ""
                                ? (
                                    formik.values.receive_order_qty *
                                    formik.values.receive_price
                                  ).toFixed(2)
                                : formik.values.receive_price
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div>
                        <input
                          type="hidden"
                          className="form-control"
                          id="purchase_order_item_id"
                          name="purchase_order_item_id"
                          value={formik.values.purchase_order_item_id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <input
                          type="hidden"
                          className="form-control"
                          id="id"
                          name="id"
                          value={formik.values.id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-10">
                        <span>
                          {formik.touched.phone && formik.errors.phone ? (
                            <span className="">{formik.errors.phone}</span>
                          ) : null}
                          {formik.touched.receive_price &&
                          formik.errors.receive_price
                            ? formik.errors.receive_price
                            : formik.touched.receive_order_qty &&
                              formik.errors.receive_order_qty
                            ? formik.errors.receive_order_qty
                            : null}
                        </span>
                      </div>
                      <div className="col-sm-2">
                        {editReceiveOrder ? (
                          <button type="submit" class="btn btntheme">
                            Edit
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-link btn-just-icon"
                          >
                            <IoIosSave className="save-icon" />
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                  <hr></hr>
                  <Table data={receivedOrders} columns={COLUMNS} />
                </div>
                <div className={style["modal-footer"]}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ReceiveOrder = (props) => {
  return ReactDOM.createPortal(
    <ReceiveOrderModal
      orderItemId={props.id}
      updatePurchaseOrder={props.updatePurchaseOrder}
      onClick={props.onClick}
    />,
    document.getElementById("modal")
  );
};
export default ReceiveOrder;
